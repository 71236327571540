import React, { Fragment, Component } from 'react'
import Footer from './sections/Footer';
import { $ }  from 'react-jquery-plugin';
import { easeCubicIn } from "d3-ease";
import VisibilitySensor from 'react-visibility-sensor';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AnimatedProgressProvider from "../charts/AnimatedProgressProvider";

class Index extends Component{
    componentDidMount(){
        function handlePreloader() {
            if($('.preloader').length){
                $('.preloader').delay(200).fadeOut(500);
            }
        }
        
        //Update Header Style and Scroll to Top
        // function headerStyle() {
        //     if($('.main-header').length){
        //         var windowpos = $(window).scrollTop();
        //         var siteHeader = $('.main-header');
        //         var scrollLink = $('.scroll-to-top');
        //         var sticky_header = $('.main-header .sticky-header');
        //         if (windowpos > 700) {
        //             siteHeader.addClass('fixed-header');
        //             scrollLink.fadeIn(300);
        //         } else{
        //             siteHeader.removeClass('fixed-header');
        //             scrollLink.fadeOut(300);
        //         }
        //     }
        // }
        // headerStyle();
    
    
        // Mega Menu Offset
        function Mega_Menu(){
            if($('.mega-menu').length){
                var menu_width = $('.mega-menu').attr("data-width");
                $('.mega-menu').width(menu_width);
                var menu_offset = $('.mega-menu').parent().offset();
                $('.mega-menu').css({left: menu_offset.left});
            }
        }
        Mega_Menu();
    
    
        //Sticky Header Hide/Show On Scroll
        // (function($) {
        //     var iScrollPos = 0;
        //     var sticky_header = $('.main-header .sticky-header');
        //     $(window).scroll(function () {
        //         var iCurScrollPos = $(this).scrollTop();
        //         if (iCurScrollPos > iScrollPos) {
        //             $('.main-header .sticky-header').css({"position": "fixed", "width": "100%", "top": "-120px"});
        //         } else {
        //             $('.main-header .sticky-header').css({"top": "0"});
        //         }
        //         iScrollPos = iCurScrollPos;
        //     });
        // })(jQuery);
    
        //Submenu Dropdown Toggle
        if($('.main-header li.dropdown ul').length){
            $('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-plus"></span></div>');
        }
    
        //Mobile Nav Hide Show
        if($('.mobile-menu').length){
            
            var mobileMenuContent = $('.main-header .nav-outer .main-menu').html();
            var mobileHeaderContent = $('.main-header .nav-outer .outer-box').html();
            var stickyMenuContent = $('.main-header .nav-outer .main-menu').html();
            $('.mobile-menu').append('<div class="close-btn"><span class="icon fa fa-times"></span></div>');
            $('.mobile-header .nav-outer').append('<div class="mobile-nav-toggler"><span class="icon fa fa-bars"></span></div>');
            $('.mobile-menu .menu-box').append(mobileMenuContent);
            $('.mobile-header .nav-outer').append(mobileHeaderContent);
            $('.sticky-header .main-menu').append(stickyMenuContent);
            $('.sticky-header .main-menu .navbar-collapse').addClass('show');
            $('.mobile-menu .menu-box .navbar-collapse').addClass('show');
            $('.mobile-menu .close-btn').on('click', function() {
                $('body').removeClass('mobile-menu-visible');
            });
    
            //Dropdown Button
            $('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
                $(this).prev('ul').slideToggle(500);
                $(this).toggleClass('active');
                $(this).parent('li').toggleClass('active');
    
            });
    
            //Megamenu Toggle
            $('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
                $(this).prev('.mega-menu').slideToggle(500);
            });
    
            //Menu Toggle Btn
            $('.mobile-nav-toggler').on('click', function() {
                $('body').addClass('mobile-menu-visible');
            });
    
            //Menu Toggle Btn
            $('.mobile-menu .menu-backdrop,.mobile-menu .close-btn').on('click', function() {
                $('body').removeClass('mobile-menu-visible');
            });
        }
    
        //Header Search
        if($('.search-btn').length) {
            $('.search-btn').on('click', function() {
                $('.main-header').addClass('search-active');
            });
            $('.close-search, .search-back-drop').on('click', function() {
                $('.main-header').removeClass('search-active');
            });
        }
    
    
        //Portfolio Carousel
        if ($('.portfolio-carousel').length) {
            $('.portfolio-carousel').owlCarousel({
                loop:false,
                margin:20,
                nav:false,
                smartSpeed: 400,
                autoplay: false,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    768:{
                        items:2
                    },
                    1024:{
                        items:3
                    }
                }
            });    		
        }
    
        //Sponsors carousel
        if ($('.sponsors-carousel').length) {
            $('.sponsors-carousel').owlCarousel({
                loop:true,
                margin:0,
                nav:false,
                smartSpeed: 700,
                autoplay: false,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:2
                    },
                    768:{
                        items:2
                    },
                    1024:{
                        items:3
                    },
                    1280:{
                        items:4
                    }
                }
            });    		
        }
    
        //Services Carousel
        if ($('.services-carousel').length) {
            $('.services-carousel').owlCarousel({
                loop:true,
                margin:0,
                nav:false,
                smartSpeed: 700,
                autoplay: false,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:2
                    },
                    768:{
                        items:2
                    },
                    1024:{
                        items:3
                    },
                    1280:{
                        items:4
                    }
                }
            });    		
        }
    
        //Recipes Carousel
        if ($('.recipes-carousel').length) {
            $('.recipes-carousel').owlCarousel({
                loop:true,
                margin:0,
                nav:false,
                smartSpeed: 400,
                autoplay: false,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    768:{
                        items:1
                    },
                    1024:{
                        items:1
                    }
                }
            });    		
        }
    
        //Testimonial Carousel
        if ($('.testimonial-carousel').length) {
            $('.testimonial-carousel').owlCarousel({
                loop:true,
                margin:30,
                nav:false,
                smartSpeed: 700,
                autoplay: true,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    1024:{
                        items:1
                    },
                }
            });    		
        }
    
        //Testimonial Carousel
        if ($('.testimonial-carousel-two').length) {
            $('.testimonial-carousel-two').owlCarousel({
                loop:false,
                margin:30,
                nav:true,
                smartSpeed: 700,
                autoplay: true,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    768:{
                        items:2
                    },
                    1024:{
                        items:3
                    },
                }
            });    		
        }
    
    
        //Team Carousel
        if ($('.team-carousel').length) {
            $('.team-carousel').owlCarousel({
                loop:false,
                margin:0,
                nav:false,
                smartSpeed: 700,
                autoplay: false,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:2
                    },
                    768:{
                        items:2
                    },
                    1024:{
                        items:3
                    },
                    1280:{
                        items:4
                    }
                }
            });    		
        }
    
        //Single Item Carousel
        if ($('.single-item-carousel').length) {
            $('.single-item-carousel').owlCarousel({
                loop:true,
                margin:0,
                nav:true,
                smartSpeed: 700,
                autoplay: false,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    1024:{
                        items:1
                    }
                }
            });    		
        }
    
        //Four Item Carousel
        if ($('.four-items-carousel').length) {
            $('.four-items-carousel').owlCarousel({
                loop:false,
                margin:0,
                nav:false,
                smartSpeed: 700,
                autoplay: false,
                navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:2
                    },
                    768:{
                        items:2
                    },
                    1024:{
                        items:3
                    },
                    1280:{
                        items:4
                    }
                }
            });    		
        }
    
        //Make Content Sticky
        if ($('.sticky-sidebar').length) {
            $('.sidebar-side').theiaStickySidebar({
              // Settings
              additionalMarginTop: 70
            });
        }
    
        // Custom Select Box
        if ($('.sortby-select').length) {
            $('.sortby-select').select2();
        }
    
        // cooked single ingredient
        if ($('.cooked-single-ingredient').length) {
            $('.cooked-single-ingredient .cooked-ingredient-checkbox').on('click', function() {
                $(this).parent('.cooked-single-ingredient').toggleClass('checked');
            });
        }
    
        // Open modal in AJAX callback
        $('#call-btn').on('click', function(event) {
          event.preventDefault();
          this.blur();
          $.get(this.href, function(html) {
            $(html).appendTo('body').modal({
                clickClose: false,
                closeClass: 'cooked-close-fsm',
                closeExisting: true,
                fadeDuration: 300,
                fadeDelay: 0.15
            });
          });
        });
    
        //Jquery Knob animation  // Pie Chart Animation
        if($('.dial').length){
            $('.dial').show(function(){
              var elm = $(this);
              var color = elm.attr('data-fgColor');  
              var perc = elm.attr('value');  
     
              elm.knob({ 
                   'value': 0, 
                    'min':0,
                    'max':100,
                    'skin':'tron',
                    'readOnly':true,
                    'thickness':0.12,
                    'dynamicDraw': true,
                    'displayInput':false
              });
    
              $({value: 0}).animate({ value: perc }, {
                  duration: 2000,
                  easing: 'swing',
                  progress: function () { elm.val(Math.ceil(this.value)).trigger('change');
                  }
              });
    
              //circular progress bar color
              $(this).append(function() {
                 // elm.parent().parent().find('.circular-bar-content').css('color',color);
                  //elm.parent().parent().find('.circular-bar-content .txt').text(perc);
              });
    
              },{accY: 20});
        }
    
        if($('.odometer').length) {
            // $('.odometer').appear(function (e) {
            //     var odo = $(".odometer");
            //     odo.each(function () {
            //         var countNumber = $(this).attr("data-count");
            //         $(this).html(countNumber);
            //     });
            // });
        }
    
        // background Video
        if ($('.video-section').length) {
            function ytb_bg() {
                $(function(){
                  $('[data-youtube]').youtube_background({});
                });
            }
            ytb_bg();
        }
    
        //Default Masonary
        function defaultMasonry() {
            if($('.masonry-items-container').length){
        
                var winDow = $(window);
                // Needed variables
                var $container=$('.masonry-items-container');
        
                $container.isotope({
                    itemSelector: '.masonry-item',
                     masonry: {
                        columnWidth : '.col-lg-4, .col-lg-3, .col-lg-6'
                     },
                    animationOptions:{
                        duration:500,
                        easing:'linear'
                    }
                });
        
                winDow.on('resize', function(){
    
                    $container.isotope({ 
                        itemSelector: '.masonry-item',
                        animationOptions: {
                            duration: 500,
                            easing	: 'linear',
                            queue	: false
                        }
                    });
                });
            }
        }
    
        defaultMasonry();
    
    
        //Sortable Masonary with Filters
        function enableMasonry() {
            if($('.sortable-masonry').length){
        
                var winDow = $(window);
                // Needed variables
                var $container=$('.sortable-masonry .items-container');
                var $filter=$('.filter-btns');
        
                $container.isotope({
                    filter:'*',
                     masonry: {
                        columnWidth : '.masonry-item.col-lg-3'
                     },
                    animationOptions:{
                        duration:500,
                        easing:'linear'
                    }
                });
                
        
                // Isotope Filter 
                $filter.find('li').on('click', function(){
                    var selector = $(this).attr('data-filter');
        
                    try {
                        $container.isotope({ 
                            filter	: selector,
                            animationOptions: {
                                duration: 500,
                                easing	: 'linear',
                                queue	: false
                            }
                        });
                    } catch(err) {
        
                    }
                    return false;
                });
        
        
                winDow.on('resize', function(){
                    var selector = $filter.find('li.active').attr('data-filter');
    
                    $container.isotope({ 
                        filter	: selector,
                        animationOptions: {
                            duration: 500,
                            easing	: 'linear',
                            queue	: false
                        }
                    });
                });
        
        
                var filterItemA	= $('.filter-btns li');
        
                filterItemA.on('click', function(){
                    var $this = $(this);
                    if ( !$this.hasClass('active')) {
                        filterItemA.removeClass('active');
                        $this.addClass('active');
                    }
                });
            }
        }
        
        enableMasonry();
    
        //MixItup Gallery
        if($('.filter-list').length){
              $('.filter-list').mixItUp();
         }
    
        //Message Box
        if($('.message-box').length){
            $('.message-box .close-btn').on('click', function(e) {
                $(this).parent('.message-box').fadeOut();
            });
        }
    
        //Accordion Box
        if($('.accordion-box').length){
            $(".accordion-box").on('click', '.acc-btn', function() {
                
                var outerBox = $(this).parents('.accordion-box');
                var target = $(this).parents('.accordion');
                
                if($(this).hasClass('active')!==true){
                    $(outerBox).find('.accordion .acc-btn').removeClass('active ');
                }
                
                if ($(this).next('.acc-content').is(':visible')){
                    return false;
                }else{
                    $(this).addClass('active');
                    $(outerBox).children('.accordion').removeClass('active-block');
                    $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                    target.addClass('active-block');
                    $(this).next('.acc-content').slideDown(300);	
                }
            });	
        }
    
        //Fact Counter + Text Count
        if($('.count-box').length){
            // $('.count-box').appear(function(){
        
            //     var $t = $(this),
            //         n = $t.find(".count-text").attr("data-stop"),
            //         r = parseInt($t.find(".count-text").attr("data-speed"), 10);
                    
            //     if (!$t.hasClass("counted")) {
            //         $t.addClass("counted");
            //         $({
            //             countNum: $t.find(".count-text").text()
            //         }).animate({
            //             countNum: n
            //         }, {
            //             duration: r,
            //             easing: "linear",
            //             step: function() {
            //                 $t.find(".count-text").text(Math.floor(this.countNum));
            //             },
            //             complete: function() {
            //                 $t.find(".count-text").text(this.countNum);
            //             }
            //         });
            //     }
                
            // },{accY: 0});
        }
    
        //Progress Bar
        if($('.progress-line').length){
            // $('.progress-line').appear(function(){
            //     var el = $(this);
            //     var percent = el.data('width');
            //     $(el).css('width',percent+'%');
            // },{accY: 0});
        }
    
        //Tabs Box
        if($('.tabs-box').length){
            $('.tabs-box .tab-buttons .tab-btn').on('click', function(e) {
                e.preventDefault();
                var target = $($(this).attr('data-tab'));
                
                if ($(target).is(':visible')){
                    return false;
                }else{
                    target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
                    $(this).addClass('active-btn');
                    target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
                    target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab animated fadeIn');
                    $(target).fadeIn(300);
                    $(target).addClass('active-tab animated fadeIn');
                }
            });
        }
    
        //Price Range Slider
        if($('.price-range-slider').length){
            $( ".price-range-slider" ).slider({
                range: true,
                min: 0,
                max: 90,
                values: [ 0, 84 ],
                slide: function( event, ui ) {
                $( "input.property-amount" ).val( ui.values[ 0 ] + " - " + ui.values[ 1 ] );
                }
            });
            
            $( "input.property-amount" ).val( $( ".price-range-slider" ).slider( "values", 0 ) + " - $" + $( ".price-range-slider" ).slider( "values", 1 ) );	
        }
    
        //LightBox / Fancybox
        if($('.lightbox-image').length) {
            $('.lightbox-image').fancybox({
                openEffect  : 'fade',
                closeEffect : 'fade',
                helpers : {
                    media : {}
                }
            });
        }
        
        //Contact Form Validation
        if($('#email-form').length){
            // $('#submit').click(function(){
                
            //     var o = new Object();
            //     var form = '#email-form';
                
            //     var username = $('#email-form .username').val();
            //     var email = $('#email-form .email').val();
                
            //     if(username == '' || email == '')
            //     {
            //         $('#email-form .response').html('<div class="failed">Please fill the required fields.</div>');
            //         return false;
            //     }
                
            //     $.ajax({
            //         url:"sendemail.php",
            //         method:"POST",
            //         data: $(form).serialize(),
            //         beforeSend:function(){
            //             $('#email-form .response').html('<div class="text-info"><img src="images/icons/preloader.gif"> Loading...</div>');
            //         },
            //         success:function(data){
            //             $('form').trigger("reset");
            //             $('#email-form .response').fadeIn().html(data);
            //             setTimeout(function(){
            //                 $('#email-form .response').fadeOut("slow");
            //             }, 5000);
            //         },
            //         error:function(){
            //             $('#email-form .response').fadeIn().html(data);
            //         }
            //     });
            // });
        }
    
        //Subscribe Form
        if($('#subscribe-form').length){
            // $('#subscribe-newslatters').click(function(){
                
            //     var o = new Object();
            //     var form = '#subscribe-form';
            //     var email = $('#subscribe-form .email').val();
                
            //     if(email == '')
            //     {
            //         $('#subscribe-form .response').html('<div class="failed">Please enter your Email Address.</div>');
            //         return false;
            //     }
                
            //     $.ajax({
            //         url:"sendnewslatters.php",
            //         method:"POST",
            //         data: $(form).serialize(),
            //         beforeSend:function(){
            //             $('#subscribe-form .response').html('<div class="text-info"><img src="images/icons/preloader.gif"> Loading...</div>');
            //         },
            //         success:function(data){
            //             $('form').trigger("reset");
            //             $('#subscribe-form .response').fadeIn().html(data);
            //             setTimeout(function(){
            //                 $('#subscribe-form .response').fadeOut("slow");
            //             }, 5000);
            //         },
            //         error:function(){
            //             $('#subscribe-form .response').fadeIn().html(data);
            //         }
            //     });
            // });
        }
    
        // Scroll to a Specific Div
        if($('.scroll-to-target').length){
            $(".scroll-to-target").on('click', function() {
                var target = $(this).attr('data-target');
               // animate
               $('html, body').animate({
                   scrollTop: $(target).offset().top
                 }, 1500);
        
            });
        }
        
        // Elements Animation
        if($('.wow').length){
            // var wow = new WOW(
            //   {
            //     boxClass:     'wow',      // animated element css class (default is wow)
            //     animateClass: 'animated', // animation css class (default is animated)
            //     offset:       0,          // distance to the element when triggering the animation (default is 0)
            //     mobile:       false,       // trigger animations on mobile devices (default is true)
            //     live:         true       // act on asynchronously loaded content (default is true)
            //   }
            // );
            // wow.init();
        }
    
    
            var fast = 200;
        $(".dir-hover .portfolio-block").hover(function(e) {
            var liPos = $(this).offset();
            var bord = comingMouse(e.pageX - liPos.left, e.pageY - liPos.top, $(this).width(), $(this).height());
            var overlay =  $(this).find($(".overlay"));
            switch (bord) {
                case "left":
                  overlay.css({"top":0,"left":"-100%"});
                  overlay.stop().animate({"left":0},fast,"linear");
                  break;
                case "right":
                  overlay.css({"top":0,"left":"100%"});
                  overlay.stop().animate({"left":0},fast,"linear");          
                  break;
                case "top":
                  overlay.css({"top":"-100%","left":0});
                  overlay.stop().animate({"top":0},fast,"linear");
                  break;
                case "bottom":
                  overlay.css({"top":"100%","left":0});
                  overlay.stop().animate({"top":0},fast,"linear");
                  break;
            }
        }, function(e) {
            var liPos = $(this).offset();
            var bord = comingMouse(e.pageX - liPos.left, e.pageY - liPos.top, $(this).width(), $(this).height());
            var overlay =  $(this).find($(".overlay"));
          switch (bord) {
                case "left":
                  overlay.stop().animate({"left":"-100%"},fast);
                  break;
                case "right":
                  overlay.stop().animate({"left":"100%"},fast);         
                  break;
                case "top":
                  overlay.stop().animate({"top":"-100%"},fast);
                  break;
                case "bottom":
                  overlay.stop().animate({"top":"100%"},fast);
                  break;
            }
        });
    
    function comingMouse(hor,vert,larg,haut) {
            var top = Math.abs(vert),
                bottom = Math.abs(vert-haut),
                left = Math.abs(hor),
                right = Math.abs(hor-larg);
    
            var min = Math.min(top,bottom,left,right);
            switch (min) {
                case left:
                    return "left";
                case right:
                    return "right";
                case top:
                    return "top";
                case bottom:
                    return "bottom";
            }
    }
       
    
    /* ==========================================================================
       When document is Resize, do
       ========================================================================== */
           $(window).on('resize', function() {
            Mega_Menu();
        });
    
    /* ==========================================================================
       When document is Scrollig, do
       ========================================================================== */
        
        // $(window).on('scroll', function() {
        //     headerStyle();
        // });
        
    /* ==========================================================================
       When document is loading, do
       ========================================================================== */
        
       
            handlePreloader();
            defaultMasonry();
            enableMasonry();
        
    }

    onScroll(){
        // if($('.main-header').length){
        //     var windowpos = $(window).scrollTop();
        //     var siteHeader = $('.main-header');
        //     var scrollLink = $('.scroll-to-top');
        //     var sticky_header = $('.main-header .sticky-header');
        //     if (windowpos > 700) {
        //         siteHeader.addClass('fixed-header');
        //         scrollLink.fadeIn(300);
        //     } else{
        //         siteHeader.removeClass('fixed-header');
        //         scrollLink.fadeOut(300);
        //     }
        // }
    }
render(){
return (
    <Fragment>
    
      <div className="page-wrapper color-scheme-purple">
        {/* Preloader */}
        <div className="preloader">
          <div className="cws_loader">
            <span>LOADING...</span>
            <div className="hex" />
            <div className="hex" />
            <div className="hex" />
            <div className="hex" />
            <div className="hex" />
            <div className="hex" />
            <div className="hex" />
          </div>
        </div>
        {/* Main Header*/}
        <header className="main-header">
          {/* Main box */}
          <div className="main-box">
            <div className="menu-box">
              <div className="logo">
                
                  {/* <img
                    src="assets/images/logo.png"
                    alt=""
                    title=""
                    srcSet="assets/images/logo-white-big.png 2x"
                  /> */}
                 YAX HOME
              </div>
              {/*Nav Box*/}
              <div className="nav-outer" style={{display:'none'}}>
                {/* Main Menu */}
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="current dropdown">
                        <a href="index.html">
                          <span>home</span>
                        </a>
                        <ul>
                          <li>
                            <a href="index.html">Home</a>
                          </li>
                          <li className="current">
                            <a href="index-2.html">Interactive Security</a>
                          </li>
                          <li>
                            <a href="index-3.html">Video Monitoring</a>
                          </li>
                          <li>
                            <a href="index-4.html">Energy Management</a>
                          </li>
                          <li>
                            <a href="index-5.html">Product Page</a>
                          </li>
                          <li>
                            <a href="index-6.html">Intelligent Home</a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown has-mega-menu">
                        <a href="about-us.html">
                          <span>Pages</span>
                        </a>
                        <div className="mega-menu" data-width="800px">
                          <div className="mega-menu-bar row">
                            <div className="column col-lg-3 col-md-3 col-sm-12">
                              <h3>About</h3>
                              <ul>
                                <li>
                                  <a href="about.html">About Us</a>
                                </li>
                                <li>
                                  <a href="services.html">Our Services</a>
                                </li>
                                <li>
                                  <a href="process.html">Our Process</a>
                                </li>
                                <li>
                                  <a href="shop-single.html">Product Description</a>
                                </li>
                                <li>
                                  <a href="elements.html">Content Elements</a>
                                </li>
                              </ul>
                            </div>
                            <div className="column col-lg-3 col-md-3 col-sm-12">
                              <h3>Portfolio</h3>
                              <ul>
                                <li>
                                  <a href="portfolio-masonry.html">Masonry</a>
                                </li>
                                <li>
                                  <a href="portfolio-two-col-sidebar.html">
                                    Two Cols with Sidebar
                                  </a>
                                </li>
                                <li>
                                  <a href="portfolio-three-col.html">Three Cols </a>
                                </li>
                                <li>
                                  <a href="portfolio-four-col-gallery.html">
                                    Four Cols Gallery{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="portfolio-four-col-wide.html">
                                    Four Cols Wide{" "}
                                  </a>
                                </li>
                                <li>
                                  <a href="portfolio-single-sidebar.html">
                                    Single Sidebar
                                  </a>
                                </li>
                                <li>
                                  <a href="portfolio-single-gallery.html">
                                    Single Gallery
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="column col-lg-3 col-md-3 col-sm-12">
                              <h3>Blog</h3>
                              <ul>
                                <li>
                                  <a href="blog-checkboard.html">Checkerboard</a>
                                </li>
                                <li>
                                  <a href="blog-masonry.html">Masonry</a>
                                </li>
                                <li>
                                  <a href="blog-masonry-full-width.html">
                                    Masonry Full Width
                                  </a>
                                </li>
                                <li>
                                  <a href="blog-two-col.html">Two Columns</a>
                                </li>
                                <li>
                                  <a href="blog-three-col.html">Three Colums</a>
                                </li>
                                <li>
                                  <a href="blog-four-col-wide.html">
                                    Four Cols Wide
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="column col-lg-3 col-md-3 col-sm-12">
                              <h3>Shop</h3>
                              <ul>
                                <li>
                                  <a href="shop.html">Shop Grid</a>
                                </li>
                                <li>
                                  <a href="shop-single.html">Product Single</a>
                                </li>
                                <li>
                                  <a href="shopping-cart.html">Shopping Cart</a>
                                </li>
                                <li>
                                  <a href="checkout.html">Checkout</a>
                                </li>
                                <li>
                                  <a href="login.html">My Account</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="dropdown">
                        <a href="about-us.html">
                          <span>Portfolio</span>
                        </a>
                        <ul>
                          <li>
                            <a href="portfolio-masonry.html">Masonry</a>
                          </li>
                          <li>
                            <a href="portfolio-two-col-sidebar.html">
                              Two Cols with Sidebar
                            </a>
                          </li>
                          <li>
                            <a href="portfolio-three-col.html">Three Cols </a>
                          </li>
                          <li>
                            <a href="portfolio-four-col-gallery.html">
                              Four Cols Gallery{" "}
                            </a>
                          </li>
                          <li>
                            <a href="portfolio-four-col-wide.html">
                              Four Cols Wide{" "}
                            </a>
                          </li>
                          <li>
                            <a href="portfolio-single-sidebar.html">
                              Single Sidebar
                            </a>
                          </li>
                          <li>
                            <a href="portfolio-single-gallery.html">
                              Single Gallery
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="portfolio-masonry.html">
                          <span>BLOG</span>
                        </a>
                        <ul className="from-right">
                          <li>
                            <a href="blog-checkboard.html">Checkerboard</a>
                          </li>
                          <li>
                            <a href="blog-masonry.html">Masonry</a>
                          </li>
                          <li>
                            <a href="blog-masonry-full-width.html">
                              Masonry Full Width
                            </a>
                          </li>
                          <li>
                            <a href="blog-two-col.html">Two Columns</a>
                          </li>
                          <li>
                            <a href="blog-three-col.html">Three Colums</a>
                          </li>
                          <li>
                            <a href="blog-four-col-wide.html">Four Cols Wide</a>
                          </li>
                          <li className="dropdown">
                            <a href="#">Blog Lists</a>
                            <ul className="from-right">
                              <li>
                                <a href="blog-small-images.html">Small Images</a>
                              </li>
                              <li>
                                <a href="blog-medium-images.html">Medium Images</a>
                              </li>
                              <li>
                                <a href="blog-large-images.html">Large Images</a>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <a href="blog-post-image.html">Post Types</a>
                            <ul className="from-right">
                              <li>
                                <a href="blog-post-image.html">Image Post</a>
                              </li>
                              <li>
                                <a href="blog-post-gallery.html">Gallery Post</a>
                              </li>
                              <li>
                                <a href="blog-post-link.html">Link Post</a>
                              </li>
                              <li>
                                <a href="blog-post-audio.html">Audio Post</a>
                              </li>
                              <li>
                                <a href="blog-post-quote.html">Quote Post</a>
                              </li>
                              <li>
                                <a href="blog-post-video.html">Video Post</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown">
                        <a href="shop.html">
                          <span>SHOP</span>
                        </a>
                        <ul className="from-right">
                          <li>
                            <a href="shop.html">Shop Grid</a>
                          </li>
                          <li>
                            <a href="shop-single.html">Product Single</a>
                          </li>
                          <li>
                            <a href="shopping-cart.html">Shopping Cart</a>
                          </li>
                          <li>
                            <a href="checkout.html">Checkout</a>
                          </li>
                          <li>
                            <a href="login.html">My Account</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="contact.html">Contacts</a>
                      </li>
                    </ul>
                  </div>
                </nav>
                {/* Main Menu End*/}
                <div className="outer-box clearfix">
                  {/* Search Btn */}
                  <div className="search-box">
                    <button className="search-btn">
                      <i className="fa fa-search" />
                    </button>
                  </div>
                  {/* Shoppping Car */}
                  <div className="cart-btn">
                    <a href="shopping-cart.html">
                      <i className="icon flaticon-shopping-cart-empty-side-view" />{" "}
                      <span className="count">2</span>
                    </a>
                    <div className="shopping-cart">
                      <ul className="shopping-cart-items">
                        <li className="cart-item">
                          <img
                            src="https://via.placeholder.com/300x300"
                            alt="#"
                            className="thumb"
                          />
                          <span className="item-name">Night Security Cam 2Z1</span>
                          <span className="item-quantity">
                            1 x <span className="item-amount">$84.00</span>
                          </span>
                          <a href="shop-single.html" className="product-detail" />
                          <button className="remove-item">
                            <span className="fa fa-times" />
                          </button>
                        </li>
                        <li className="cart-item">
                          <img
                            src="https://via.placeholder.com/300x300"
                            alt="#"
                            className="thumb"
                          />
                          <span className="item-name">
                            Day Security Camera B2Z1
                          </span>
                          <span className="item-quantity">
                            1 x <span className="item-amount">$13.00</span>
                          </span>
                          <a href="shop-single.html" className="product-detail" />
                          <button className="remove-item">
                            <span className="fa fa-times" />
                          </button>
                        </li>
                      </ul>
                      <div className="cart-footer">
                        <div className="shopping-cart-total">
                          <strong>Subtotal:</strong> $97.00
                        </div>
                        <a href="cart.html" className="theme-btn">
                          View Cart
                        </a>
                        <a href="checkout.html" className="theme-btn">
                          Checkout
                        </a>
                      </div>
                    </div>{" "}
                    {/*end shopping-cart */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Sticky Header  */}
          <div className="sticky-header">
            <div className="outer-box">
              {/*Logo*/}
              {/* <div className="logo">
                <a href="#" title="Sticky Logo">
                  <img src="assets/images/sticky-logo.png" alt="Sticky Logo" />
                </a>
              </div> */}
              <div className="logoblack">
                
                {/* <img
                  src="assets/images/logo.png"
                  alt=""
                  title=""
                  srcSet="assets/images/logo-white-big.png 2x"
                /> */}
               YAX HOME
            </div>
              {/*Nav Outer*/}
              <div className="nav-outer">
                {/* Main Menu */}
                <nav className="main-menu">
                  {/*Keep This Empty / Menu will come through Javascript*/}
                </nav>
                {/* Main Menu End*/}
              </div>
            </div>
          </div>
          {/* End Sticky Menu */}
          {/* Mobile Header */}
          <div className="mobile-header">
            {/* <div className="logo">
              <a href="index.html">
                <img
                  src="assets/images/logo.png"
                  alt=""
                  title=""
                  srcSet="assets/images/logo-white-big.png 2x"
                />
              </a>
            </div> */}
            <div className="logo">
                
                {/* <img
                  src="assets/images/logo.png"
                  alt=""
                  title=""
                  srcSet="assets/images/logo-white-big.png 2x"
                /> */}
               YAX HOME
            </div>
            {/*Nav Box*/}
            <div className="nav-outer clearfix" style={{display:'none'}}>
              {/*Keep This Empty / Menu will come through Javascript*/}
            </div>
          </div>
          {/* Mobile Menu  */}
          <div className="mobile-menu" style={{display:'none'}}>
            <nav className="menu-box">
              {/* <div className="nav-logo">
                <a href="index.html">
                  <img
                    src="assets/images/logo.png"
                    alt=""
                    title=""
                    srcSet="assets/images/logo-white-big.png 2x"
                  />
                </a>
              </div> */}
              <div className="logo">
                
                {/* <img
                  src="assets/images/logo.png"
                  alt=""
                  title=""
                  srcSet="assets/images/logo-white-big.png 2x"
                /> */}
               YAX HOME
            </div>
              {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
            </nav>
          </div>
          {/* End Mobile Menu */}
          {/* Header Search */}
          <div className="search-popup">
            <span className="search-back-drop" />
            <div className="search-inner">
              <button className="close-search">
                <span className="fa fa-times" />
              </button>
              <form method="post" action="blog-showcase.html">
                <div className="form-group">
                  <input
                    type="search"
                    name="search-field"
                    defaultValue=""
                    placeholder="Search..."
                    required=""
                  />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* End Header Search */}
        </header>
        {/*End Main Header */}
        {/*Main Slider*/}
        <section className="main-slider" >
            {/* <div ><img src='assets/images/main-slider/pattern/2.png'></img></div> */}
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img className="d-block w-100" src="assets/images/main-slider/pattern/2.png" alt="First slide"/>
          <div className="carousel-caption d-none d-md-block">
        <h5>Be A Part Of Revolution</h5>
        <p>Get Ready To Dive Into The Internet of Things Revolution to automate your home<br/> Explore our products to know more. </p>
      </div>
        </div>
        <div className="carousel-item">
        <img className="d-block w-100" src="assets/images/main-slider/pattern/2.png" alt="First slide"/>
          <div className="carousel-caption d-none d-md-block">
        <h5>Hosted on Google Cloud</h5>
        <p>We hosted our services on Google Cloud platform which ensures high availability so you are always connected to your devices </p>
      </div>
        </div>
        <div className="carousel-item">
        <img className="d-block w-100" src="assets/images/main-slider/pattern/2.png" alt="First slide"/>
          <div className="carousel-caption d-none d-md-block">
        <h5>Security </h5>
        <p>We ensure your privacy, we encrypt all the communication to backend servers</p>
      </div>
        </div>
      </div>
      <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
        </section>
        {/*End Main Slider*/}
        {/* Security Features  */}
        <section className="security-features">
          {/* Background Layers */}
          <div className="background-layers">
            <div className="cws-image-bg style-four">
              <div className="cws-overlay-bg purple-gradient-one" />
              <div className="cws-overlay-bg purple-gradient-two" />
            </div>
          </div>
          <div className="auto-container">
            <div className="row">
              {/* Feature Block Two */}
              <div className="feature-block-two col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="icon-box">
                    <div className="icon_shape">
                      <svg viewBox="0 0 73 86">
                        <defs>
                          <filter id="shape_gaurd">
                            <feGaussianBlur in="SourceAlpha" stdDeviation={2} />
                            <feOffset dx={1} dy={1} />
                            <feComponentTransfer>
                              <feFuncA type="linear" slope="0.6" />
                            </feComponentTransfer>
                            <feMerge>
                              <feMergeNode />
                              <feMergeNode in="SourceGraphic" />
                            </feMerge>
                          </filter>
                        </defs>{" "}
                        <path
                          filter="url(#shape_gaurd)"
                          d="M167.52,434.43a0.85,0.85,0,0,1-.28,0c-23.87-8.32-32.34-23.22-32.34-33.44V367.53a0.85,0.85,0,0,1,.57-0.81l31.78-10.56a0.85,0.85,0,0,1,.54,0l31.77,10.57a0.85,0.85,0,0,1,.6.8v33.41c0,10.23-8.47,25.13-32.34,33.45A0.86,0.86,0,0,1,167.52,434.43Z"
                          transform="translate(-132 -353.43)"
                        />
                      </svg>
                    </div>
                    <span className="icon flaticon-lock-1" />
                  </div>
                  <div className="lower-content">
                    <h4>Access Control</h4>
                    <p>
                      Share devices with your family members
                    </p>
                    {/* <a href="#" className="link">
                      <span className="fa fa-long-arrow-alt-right" />
                    </a> */}
                  </div>
                </div>
              </div>
              {/* Feature Block Two */}
              <div className="feature-block-two col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="icon-box">
                    <div className="icon_shape">
                      <svg viewBox="0 0 73 86">
                        <defs>
                          <filter id="shape_gaurd2">
                            <feGaussianBlur in="SourceAlpha" stdDeviation={2} />
                            <feOffset dx={1} dy={1} />
                            <feComponentTransfer>
                              <feFuncA type="linear" slope="0.6" />
                            </feComponentTransfer>
                            <feMerge>
                              <feMergeNode />
                              <feMergeNode in="SourceGraphic" />
                            </feMerge>
                          </filter>
                        </defs>{" "}
                        <path
                          filter="url(#shape_gaurd2)"
                          d="M167.52,434.43a0.85,0.85,0,0,1-.28,0c-23.87-8.32-32.34-23.22-32.34-33.44V367.53a0.85,0.85,0,0,1,.57-0.81l31.78-10.56a0.85,0.85,0,0,1,.54,0l31.77,10.57a0.85,0.85,0,0,1,.6.8v33.41c0,10.23-8.47,25.13-32.34,33.45A0.86,0.86,0,0,1,167.52,434.43Z"
                          transform="translate(-132 -353.43)"
                        />
                      </svg>
                    </div>
                    <span className="icon flaticon-cctv-1" />
                  </div>
                  <div className="lower-content">
                    <h4>Setup and Installation by skilled personel</h4>
                    <p>
                      We provide installation service for all our products
                    </p>
                    {/* <a href="#" className="link">
                      <span className="fa fa-long-arrow-alt-right" />
                    </a> */}
                  </div>
                </div>
              </div>
              {/* Feature Block Two */}
              <div className="feature-block-two col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="icon-box">
                    <div className="icon_shape">
                      <svg viewBox="0 0 73 86">
                        <defs>
                          <filter id="shape_gaurd3">
                            <feGaussianBlur in="SourceAlpha" stdDeviation={2} />
                            <feOffset dx={1} dy={1} />
                            <feComponentTransfer>
                              <feFuncA type="linear" slope="0.6" />
                            </feComponentTransfer>
                            <feMerge>
                              <feMergeNode />
                              <feMergeNode in="SourceGraphic" />
                            </feMerge>
                          </filter>
                        </defs>{" "}
                        <path
                          filter="url(#shape_gaurd3)"
                          d="M167.52,434.43a0.85,0.85,0,0,1-.28,0c-23.87-8.32-32.34-23.22-32.34-33.44V367.53a0.85,0.85,0,0,1,.57-0.81l31.78-10.56a0.85,0.85,0,0,1,.54,0l31.77,10.57a0.85,0.85,0,0,1,.6.8v33.41c0,10.23-8.47,25.13-32.34,33.45A0.86,0.86,0,0,1,167.52,434.43Z"
                          transform="translate(-132 -353.43)"
                        />
                      </svg>
                    </div>
                    <span className="icon flaticon-alarm" />
                  </div>
                  <div className="lower-content">
                    <h4>Easy setup</h4>
                    <p>
                      Quick setup from your mobile
                    </p>
                    {/* <a href="#" className="link">
                      <span className="fa fa-long-arrow-alt-right" />
                    </a> */}
                  </div>
                </div>
              </div>
              {/* Feature Block Two */}
              <div className="feature-block-two col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="icon-box">
                    <div className="icon_shape">
                      <svg viewBox="0 0 73 86">
                        <defs>
                          <filter id="shape_gaurd4">
                            <feGaussianBlur in="SourceAlpha" stdDeviation={2} />
                            <feOffset dx={1} dy={1} />
                            <feComponentTransfer>
                              <feFuncA type="linear" slope="0.6" />
                            </feComponentTransfer>
                            <feMerge>
                              <feMergeNode />
                              <feMergeNode in="SourceGraphic" />
                            </feMerge>
                          </filter>
                        </defs>{" "}
                        <path
                          filter="url(#shape_gaurd4)"
                          d="M167.52,434.43a0.85,0.85,0,0,1-.28,0c-23.87-8.32-32.34-23.22-32.34-33.44V367.53a0.85,0.85,0,0,1,.57-0.81l31.78-10.56a0.85,0.85,0,0,1,.54,0l31.77,10.57a0.85,0.85,0,0,1,.6.8v33.41c0,10.23-8.47,25.13-32.34,33.45A0.86,0.86,0,0,1,167.52,434.43Z"
                          transform="translate(-132 -353.43)"
                        />
                      </svg>
                    </div>
                    <span className="icon flaticon-eye" />
                  </div>
                  <div className="lower-content">
                    <h4>24/7 Monitoring</h4>
                    <p>
                      Monitor devices anytime from anywhere
                    </p>
                    {/* <a href="#" className="link">
                      <span className="fa fa-long-arrow-alt-right" />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*End Security Features */}
        {/* Security Package */}
        <section
          className="security-package"
          style={{ backgroundImage: "assets/images/smart-home-touch-gesture.jpg" }}
        >
          <div className="auto-container">
            <div className="row">
              {/* Content Column */}
              <div className="content-column col-lg-7 col-md-7 col-sm-12 order-2">
                <div className="sec-title">
                  <h2>Control Everything With Your Fingertips Or Your Voice</h2>
                  <div className="text">
                  We have products lined up in home automation with Smart Plug, Smart Holder, Switch board with temperature and proximity sensor. We have designed our own WIFI module YAK-M-Mini which is based on Espressif ESP8285 SoC. 
                  </div>
                </div>
                <h4>Product Portfolio</h4>
                <ul className="package-list clearfix">
                  <li>Smart Switchboard</li>
                  <li>Smart Holder</li>
                  {/* <li>Control Panel</li>
                  <li>Outdoor Wireless HD Camera</li>
                  <li>Cellular Communication</li>
                  <li>Interior HD Camera</li>
                  <li>Door/Window Sensors</li>
                  <li>Touchscreen Keyless Door Lock</li> */}
                </ul>
                <span className="btn-box">
                  <a href="#" style={{pointerEvents:'none'}} className="text-decoration-none theme-btn btn-style-one large bg-purple">
                    Works with Google Home
                  </a>
                </span>&nbsp;&nbsp;<br/><br/><br/><span className="btn-box">
                  <a href="#"  style={{pointerEvents:'none'}} className="text-decoration-none theme-btn btn-style-one large bg-purple">
                    Works with Alexa
                  </a>
                </span>
              </div>
              {/* Image Column */}
              <div className="image-column col-lg-5 col-md-5 col-sm-12">
                <figure className="image">
                  <img src="assets/images/smart-home-touch-gesture.jpg" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </section>
        {/* End Security Package */}
        {/* Real Time Response */}
        <section className="realtime-response">
          {/* Background Layers */}
          <div className="background-layers">
            <div className="cws-triangle-overlay bg-purple-one" />
            <div
              className="cws-image-bg"
              style={{
                backgroundImage: "url(assets/images/realtime.jpg)"
              }}
            >
              <div className="cws-overlay-bg bg-purple-left" />
              <div className="cws-overlay-bg bg-purple-right" />
            </div>
            <div className="cws-triangle-overlay bg-purple-one bottom-left" />
          </div>
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12 text-right">
                <div className="sec-title light">
                  <h2>REAL-TIME RESPONSE</h2>
                </div>
                <div className="feature-outer">
                  {/* Feature Block Three */}
                  <div className="feature-block-three">
                    <div className="inner-box">
                      <div className="icon-box">
                        <span className="icon flaticon-wifi-2" />
                      </div>
                      <h4>Connect and setup over WiFi</h4>
                      <p>
                        Device can be setup using WiFi 2.4GHz
                      </p>
                    </div>
                  </div>
                  {/* Feature Block Three */}
                  <div className="feature-block-three">
                    <div className="inner-box">
                      <div className="icon-box">
                        <span className="icon flaticon-flasher" />
                      </div>
                      <h4>Protect your house remotely</h4>
                      <p>
                        You can monitor devices remotely to identify fraudulant activities
                      </p>
                    </div>
                  </div>
                  {/* Feature Block Three */}
                  <div className="feature-block-three">
                    <div className="inner-box">
                      <div className="icon-box">
                        <span className="icon flaticon-umbrella" />
                      </div>
                      <h4>Setup Device Schedules</h4>
                      <p>
                        You can setup how device can respond to weather conditions
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="btn-box">
                  <a href="#" className="theme-btn btn-style-one large bg-purple">
                    <i className="fa fa-camera" /> WATCH VIDEO
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* End Real Time Response */}
        {/* Quality Equipment */}
        <section className="quality-equipment">
          <div className="auto-container">
            <div className="sec-title">
              <h2>QUALITY EQUIPMENT</h2>
              <div className="text">
                We have designed our own Wifi Module which has better range and efficiency with qualtity components. We manufacture our products in India.
              </div>
            </div>
            <div className="device-img">
              <figure className="image">
                <img src="assets/images/pcb-components.jpg" alt="" />
              </figure>
            </div>
            {/* <div className="btn-box">
              <a
                href="shop.html"
                className="theme-btn btn-style-one large bg-purple"
              >
                Go To Shop
              </a>
            </div> */}
          </div>
        </section>
        {/* End Quality Equipment */}
        {/* Installation Service */}
        <section className="installation-service">
          {/* Background Layers */}
          <div className="background-layers">
            <div className="cws-triangle-overlay top-right" />
            <div
              className="cws-image-bg style-five"
              style={{
                backgroundImage: "url(/assets/images/installation.jpg)"
              }}
            >
              <div className="cws-overlay-bg" />
              <div className="cws-triangle-overlay" />
            </div>
          </div>
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-md-6 col-sm-12 offset-6">
                <div className="inner-column">
                  <div className="sec-title light">
                    <h2>INSTALLATION SERVICE</h2>
                    <div className="text">
                      We provide doorstep installation service so you dont need to burn your hands
                    </div>
                  </div>
                  {/* Services Icons */}
                  <div className="services-icons row">
                    <div className="service-icon col-lg-3 col-md-3 col-sm-12">
                      <a href="#">
                        <span className="icon flaticon-nuclear-energy" />
                      </a>
                    </div>
                    <div className="service-icon col-lg-3 col-md-3 col-sm-12">
                      <a href="#">
                        <span className="icon flaticon-safety-belt" />
                      </a>
                    </div>
                    <div className="service-icon col-lg-3 col-md-3 col-sm-12">
                      <a href="#">
                        <span className="icon flaticon-speaker" />
                      </a>
                    </div>
                  </div>
                  <div className="btn-box">
                    <a
                      href="services.html"
                      className="theme-btn btn-style-one large bg-purple" style={{display:'none'}}
                    >
                      FIND OUT MORE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Installation Service */}
        {/* Why Choose Us */}
        <section className="why-choose-us">
          {/* Background Layers */}
          <div className="background-layers">
            <div
              className="cws-image-bg style-six"
              style={{
                backgroundImage: "url(/assets/images/businessman.jpg)",
                backgroundSize:'cover'
              }}
            >
              <div className="cws-overlay-bg bg-gradient" />
            </div>
            <div className="cws-triangle-overlay bottom-right" />
          </div>
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-md-6 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title light">
                    <h2>Send Inquiry</h2>
                    <div className="text">
                  Please contact us at <a href="mailto:support@yakshatantra.com" className='text'>support@yakshatantra.com</a></div>
                  </div>
                  
                  <div className="btn-box">
                    <a
                      href="mailto:support@yakshatantra.com"
                      className="theme-btn btn-style-one large"
                    >
                      Send mail
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Why Choose Us */}
        {/* App Download */}
        <section className="app-download">
          <div
            className="cws-background-image"
            style={{ backgroundImage: "url(assets/images/remotecontrol.jpg)" }}
          />
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-lg-6 offset-6 col-md-6 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <h2>REMOTE ACCESS</h2>
                    <div className="text">
                      Easily control devices from YAX Home app which can be downloaded from Playstore or Appstore
                    </div>
                  </div>
                  <div className="download-btn row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <a href="#">
                        <img src="assets/images/icons/app-store.png" alt="" />
                      </a>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <a href="#">
                        <img src="assets/images/icons/play-store.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End App Download */}
        <Footer></Footer>
      </div>
      {/* End Page Wrapper */}
      {/* Scroll To Top */}
      <button className="scroll-to-top scroll-to-target" data-target="html">
        <span className="fa fa-long-arrow-alt-up" />
      </button>
      {/*Revolution Slider*/}
      {/*Revolution Slider*/}
    
    
    </Fragment>
        );
}

}


export default Index