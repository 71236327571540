import react,{Fragment} from 'react'

function Footer(){
    return (
        <Fragment>
                {/* Main Footer */}
    <footer
      className="main-footer"
      style={{ backgroundImage: "url(assets/images/footer-background.jpg)" }}
    >
      <div className="auto-container">
        {/*Widgets Section*/}
        <div className="widgets-section">
          <div className="row">
            {/*Footer Column*/}
            <div className="footer-column col-lg-4 col-md-6 col-sm-12">
              <div className="footer-widget about-widget">
                <h4 className="widget-title">YAX Home</h4>
                <div className="widget-content">
                  <div className="text">
                    Control all your devices from anywhere in the world
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="footer-column col-lg-4 col-md-6 col-sm-12">
              <div className="footer-widget recent-posts">
                <h4 className="widget-title">LATEST POSTS</h4>
                
                <div className="widget-content">
                  <article className="post">
                    <div className="thumb">
                      <a href="blog-detail.html">
                        <img src="https://via.placeholder.com/80x80" alt="" />
                      </a>
                    </div>
                    <h5>
                      <a href="blog-detail.html">Magna arcu</a>
                    </h5>
                    <span className="cat">Alarm</span>
                    <div className="text">Vitae est et arcu semper, moll</div>
                  </article>
                </div>
              </div>
            </div> */}
            {/*Footer Column*/}
            <div className="footer-column col-lg-4 col-md-6 col-sm-12">
              <div className="footer-widget contact-widget">
                <h4 className="widget-title">OUR CONTACTS</h4>
                <div className="widget-content">
                  <ul className="contact-list">
                    <li>
                      <i className="far fa-envelope" />
                      <a href="mialto:support@yakshatantra.com">
                        support@yakshatantra.com
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      <a href="tel: +917385516725">(+91) 7385516725</a>
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      <a href="tel: +919404792215">(+91) 9404792215</a>
                    </li>
                    {/* <li>
                      <div className="social-icon">
                        <a href="#">
                          <i className="fab fa-facebook-f" />
                        </a>
                        <a href="#">
                          <i className="fab fa-twitter" />
                        </a>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                        <a href="#">
                          <i className="fab fa-linkedin-in" />
                        </a>
                        <a href="#">
                          <i className="fa fa-signal" />
                        </a>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Footer Bottom*/}
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="copyright-text">
            <p>
              <span>YAX Home</span> - Join the revolution
            </p>
          </div>
        </div>
      </div>
    </footer>
    {/* End Main Footer */}
        </Fragment>
    )
}


export default Footer;