// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth,RecaptchaVerifier,signInWithPhoneNumber } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDisM_Qj6CsZtb9OyWviWPmaPuqyaw5fBI",
  authDomain: "yax-iot.firebaseapp.com",
  databaseURL: "https://yax-iot-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "yax-iot",
  storageBucket: "yax-iot.appspot.com",
  messagingSenderId: "1028565813359",
  appId: "1:1028565813359:web:4db524459c4a25ee60e59a"
};

export const setupRecaptcha= async (number, successCallback, expiredCallback)=>{
  const recaptchaVerifier=new RecaptchaVerifier('recaptcha-container',{
    'callback': successCallback,'expired-callback':expiredCallback
  },auth);
  recaptchaVerifier.render()
  return await signInWithPhoneNumber(auth,number, recaptchaVerifier)
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics=getAnalytics(app)
export const auth =getAuth(app)