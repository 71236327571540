import { Routes, Route} from 'react-router-dom'
import './App.css';
import Index from './pages/Index';
import LinkGoogle from './pages/linkgoogle';
import Login  from './pages/login';
import LinkAlexa from './pages/linkalexa';
import DeleteAccount from './pages/deleteAccount';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Index/>}></Route>
      <Route path='login' element={<Login/>}></Route>
	  <Route path='google' element={<LinkGoogle/>}></Route>
    <Route path='alexa' element={<LinkAlexa/>}></Route>
    <Route path='deleteAccount' element={<DeleteAccount/>}></Route>
    </Routes>
  );
}

export default App;
