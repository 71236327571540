import React, {Fragment,Component, useState} from 'react'
import {$} from 'react-jquery-plugin';
import Form from 'react-bootstrap/Form';
import Footer from './sections/Footer'
import {auth, setupRecaptcha} from '../config/firebase'
import {signInWithPhoneNumber, PhoneAuthProvider,signInWithCredential} from 'firebase/auth'
import PhoneInput from 'react-phone-number-input'
const lookup = require("coordinate_to_country");
const util = require('util');


class LinkGoogle extends Component {
	constructor(props) {
		super(props);
		this.state={
			'count':0,
			mobileNo:"",
			otp:"",
			error:"",
			confirmObject:"",
			otpState:"MOBILE_INPUT",
			authCode:"",
			countryCode:'US'
		}
		//const unsubscribe = auth.onAuthStateChanged(this.authStateChangeCallback);
		this.apiURL = 'https://ipapi.co/json/'
	  }

getQueryVariable = (variable)=> {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return (false);
    }

	getUserLocationFromAPI = () => {
		if ("geolocation" in navigator) {
			console.log("Available");
			navigator.geolocation.getCurrentPosition(this.updateLocation,this.updateLocationError,{timeout:10000});
			 
		  } else {
			console.log("Not Available");
		  }
		// const location = useGeoLocation()
		// console.log(location.country);
	}

	updateLocation=(position)=>{
		console.log("Latitude is :", position.coords.latitude);
		console.log("Longitude is :", position.coords.longitude);
		const country = lookup(position.coords.latitude, position.coords.longitude,true);
		console.log(country)
		if(country.length>0){
			this.setState({countryCode:country[0]})
		}
	}

	updateLocationError=(err)=>{
		console.log(err)
		// console.log("Latitude is :", position.coords.latitude);
		// console.log("Longitude is :", position.coords.longitude);
		// const country = lookup(position.coords.latitude, position.coords.longitude,true);
		// console.log(country)
		// if(country.length>0){
		// 	this.setState({countryCode:country[0]})
		// }
	}

	authStateChangeCallback=(user)=> {
		if (user) {
		  // Some Android devices can automatically process the verification code (OTP) message, and the user would NOT need to enter the code.
		  // Actually, if he/she tries to enter it, he/she will get an error message because the code was already used in the background.
		  // In this function, make sure you hide the component(s) for entering the code and/or navigate away from this screen.
		  // It is also recommended to display a message to the user informing him/her that he/she has successfully logged in.
		  console.log("user = "+user)
		}
	  }
	componentDidMount=async () => {
		this.getUserLocationFromAPI()
		//console.log(JSON.stringify(loc))
		function handlePreloader() {
			if ($('.preloader').length) {
				$('.preloader').delay(200).fadeOut(500);
			}
		}

		//Update Header Style and Scroll to Top
		// function headerStyle() {
		//     if($('.main-header').length){
		//         var windowpos = $(window).scrollTop();
		//         var siteHeader = $('.main-header');
		//         var scrollLink = $('.scroll-to-top');
		//         var sticky_header = $('.main-header .sticky-header');
		//         if (windowpos > 700) {
		//             siteHeader.addClass('fixed-header');
		//             scrollLink.fadeIn(300);
		//         } else{
		//             siteHeader.removeClass('fixed-header');
		//             scrollLink.fadeOut(300);
		//         }
		//     }
		// }
		// headerStyle();


		// Mega Menu Offset
		function Mega_Menu() {
			if ($('.mega-menu').length) {
				var menu_width = $('.mega-menu').attr("data-width");
				$('.mega-menu').width(menu_width);
				var menu_offset = $('.mega-menu').parent().offset();
				$('.mega-menu').css({left: menu_offset.left});
			}
		}
		Mega_Menu();


		//Sticky Header Hide/Show On Scroll
		// (function($) {
		//     var iScrollPos = 0;
		//     var sticky_header = $('.main-header .sticky-header');
		//     $(window).scroll(function () {
		//         var iCurScrollPos = $(this).scrollTop();
		//         if (iCurScrollPos > iScrollPos) {
		//             $('.main-header .sticky-header').css({"position": "fixed", "width": "100%", "top": "-120px"});
		//         } else {
		//             $('.main-header .sticky-header').css({"top": "0"});
		//         }
		//         iScrollPos = iCurScrollPos;
		//     });
		// })(jQuery);

		//Submenu Dropdown Toggle
		if ($('.main-header li.dropdown ul').length) {
			$('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-plus"></span></div>');
		}

		//Mobile Nav Hide Show
		if ($('.mobile-menu').length) {

			var mobileMenuContent = $('.main-header .nav-outer .main-menu').html();
			var mobileHeaderContent = $('.main-header .nav-outer .outer-box').html();
			var stickyMenuContent = $('.main-header .nav-outer .main-menu').html();
			$('.mobile-menu').append('<div class="close-btn"><span class="icon fa fa-times"></span></div>');
			$('.mobile-header .nav-outer').append('<div class="mobile-nav-toggler"><span class="icon fa fa-bars"></span></div>');
			$('.mobile-menu .menu-box').append(mobileMenuContent);
			$('.mobile-header .nav-outer').append(mobileHeaderContent);
			$('.sticky-header .main-menu').append(stickyMenuContent);
			$('.sticky-header .main-menu .navbar-collapse').addClass('show');
			$('.mobile-menu .menu-box .navbar-collapse').addClass('show');
			$('.mobile-menu .close-btn').on('click', function () {
				$('body').removeClass('mobile-menu-visible');
			});

			//Dropdown Button
			$('.mobile-menu li.dropdown .dropdown-btn').on('click', function () {
				$(this).prev('ul').slideToggle(500);
				$(this).toggleClass('active');
				$(this).parent('li').toggleClass('active');

			});

			//Megamenu Toggle
			$('.mobile-menu li.dropdown .dropdown-btn').on('click', function () {
				$(this).prev('.mega-menu').slideToggle(500);
			});

			//Menu Toggle Btn
			$('.mobile-nav-toggler').on('click', function () {
				$('body').addClass('mobile-menu-visible');
			});

			//Menu Toggle Btn
			$('.mobile-menu .menu-backdrop,.mobile-menu .close-btn').on('click', function () {
				$('body').removeClass('mobile-menu-visible');
			});
		}

		//Header Search
		if ($('.search-btn').length) {
			$('.search-btn').on('click', function () {
				$('.main-header').addClass('search-active');
			});
			$('.close-search, .search-back-drop').on('click', function () {
				$('.main-header').removeClass('search-active');
			});
		}


		//Portfolio Carousel
		if ($('.portfolio-carousel').length) {
			$('.portfolio-carousel').owlCarousel({
				loop: false,
				margin: 20,
				nav: false,
				smartSpeed: 400,
				autoplay: false,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					600: {
						items: 1
					},
					768: {
						items: 2
					},
					1024: {
						items: 3
					}
				}
			});
		}

		//Sponsors carousel
		if ($('.sponsors-carousel').length) {
			$('.sponsors-carousel').owlCarousel({
				loop: true,
				margin: 0,
				nav: false,
				smartSpeed: 700,
				autoplay: false,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					600: {
						items: 2
					},
					768: {
						items: 2
					},
					1024: {
						items: 3
					},
					1280: {
						items: 4
					}
				}
			});
		}

		//Services Carousel
		if ($('.services-carousel').length) {
			$('.services-carousel').owlCarousel({
				loop: true,
				margin: 0,
				nav: false,
				smartSpeed: 700,
				autoplay: false,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					600: {
						items: 2
					},
					768: {
						items: 2
					},
					1024: {
						items: 3
					},
					1280: {
						items: 4
					}
				}
			});
		}

		//Recipes Carousel
		if ($('.recipes-carousel').length) {
			$('.recipes-carousel').owlCarousel({
				loop: true,
				margin: 0,
				nav: false,
				smartSpeed: 400,
				autoplay: false,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 1
					},
					1024: {
						items: 1
					}
				}
			});
		}

		//Testimonial Carousel
		if ($('.testimonial-carousel').length) {
			$('.testimonial-carousel').owlCarousel({
				loop: true,
				margin: 30,
				nav: false,
				smartSpeed: 700,
				autoplay: true,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					600: {
						items: 1
					},
					1024: {
						items: 1
					},
				}
			});
		}

		//Testimonial Carousel
		if ($('.testimonial-carousel-two').length) {
			$('.testimonial-carousel-two').owlCarousel({
				loop: false,
				margin: 30,
				nav: true,
				smartSpeed: 700,
				autoplay: true,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					1024: {
						items: 3
					},
				}
			});
		}


		//Team Carousel
		if ($('.team-carousel').length) {
			$('.team-carousel').owlCarousel({
				loop: false,
				margin: 0,
				nav: false,
				smartSpeed: 700,
				autoplay: false,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					600: {
						items: 2
					},
					768: {
						items: 2
					},
					1024: {
						items: 3
					},
					1280: {
						items: 4
					}
				}
			});
		}

		//Single Item Carousel
		if ($('.single-item-carousel').length) {
			$('.single-item-carousel').owlCarousel({
				loop: true,
				margin: 0,
				nav: true,
				smartSpeed: 700,
				autoplay: false,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					600: {
						items: 1
					},
					1024: {
						items: 1
					}
				}
			});
		}

		//Four Item Carousel
		if ($('.four-items-carousel').length) {
			$('.four-items-carousel').owlCarousel({
				loop: false,
				margin: 0,
				nav: false,
				smartSpeed: 700,
				autoplay: false,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				responsive: {
					0: {
						items: 1
					},
					600: {
						items: 2
					},
					768: {
						items: 2
					},
					1024: {
						items: 3
					},
					1280: {
						items: 4
					}
				}
			});
		}

		//Make Content Sticky
		if ($('.sticky-sidebar').length) {
			$('.sidebar-side').theiaStickySidebar({
				// Settings
				additionalMarginTop: 70
			});
		}

		// Custom Select Box
		if ($('.sortby-select').length) {
			$('.sortby-select').select2();
		}

		// cooked single ingredient
		if ($('.cooked-single-ingredient').length) {
			$('.cooked-single-ingredient .cooked-ingredient-checkbox').on('click', function () {
				$(this).parent('.cooked-single-ingredient').toggleClass('checked');
			});
		}

		// Open modal in AJAX callback
		$('#call-btn').on('click', function (event) {
			event.preventDefault();
			this.blur();
			$.get(this.href, function (html) {
				$(html).appendTo('body').modal({
					clickClose: false,
					closeClass: 'cooked-close-fsm',
					closeExisting: true,
					fadeDuration: 300,
					fadeDelay: 0.15
				});
			});
		});

		//Jquery Knob animation  // Pie Chart Animation
		if ($('.dial').length) {
			$('.dial').show(function () {
				var elm = $(this);
				var color = elm.attr('data-fgColor');
				var perc = elm.attr('value');

				elm.knob({
					'value': 0,
					'min': 0,
					'max': 100,
					'skin': 'tron',
					'readOnly': true,
					'thickness': 0.12,
					'dynamicDraw': true,
					'displayInput': false
				});

				$({value: 0}).animate({value: perc}, {
					duration: 2000,
					easing: 'swing',
					progress: function () {
						elm.val(Math.ceil(this.value)).trigger('change');
					}
				});

				//circular progress bar color
				$(this).append(function () {
					// elm.parent().parent().find('.circular-bar-content').css('color',color);
					//elm.parent().parent().find('.circular-bar-content .txt').text(perc);
				});

			}, {accY: 20});
		}

		if ($('.odometer').length) {
			// $('.odometer').appear(function (e) {
			//     var odo = $(".odometer");
			//     odo.each(function () {
			//         var countNumber = $(this).attr("data-count");
			//         $(this).html(countNumber);
			//     });
			// });
		}

		// background Video
		if ($('.video-section').length) {
			function ytb_bg() {
				$(function () {
					$('[data-youtube]').youtube_background({});
				});
			}
			ytb_bg();
		}

		//Default Masonary
		function defaultMasonry() {
			if ($('.masonry-items-container').length) {

				var winDow = $(window);
				// Needed variables
				var $container = $('.masonry-items-container');

				$container.isotope({
					itemSelector: '.masonry-item',
					masonry: {
						columnWidth: '.col-lg-4, .col-lg-3, .col-lg-6'
					},
					animationOptions: {
						duration: 500,
						easing: 'linear'
					}
				});

				winDow.on('resize', function () {

					$container.isotope({
						itemSelector: '.masonry-item',
						animationOptions: {
							duration: 500,
							easing: 'linear',
							queue: false
						}
					});
				});
			}
		}

		defaultMasonry();


		//Sortable Masonary with Filters
		function enableMasonry() {
			if ($('.sortable-masonry').length) {

				var winDow = $(window);
				// Needed variables
				var $container = $('.sortable-masonry .items-container');
				var $filter = $('.filter-btns');

				$container.isotope({
					filter: '*',
					masonry: {
						columnWidth: '.masonry-item.col-lg-3'
					},
					animationOptions: {
						duration: 500,
						easing: 'linear'
					}
				});


				// Isotope Filter 
				$filter.find('li').on('click', function () {
					var selector = $(this).attr('data-filter');

					try {
						$container.isotope({
							filter: selector,
							animationOptions: {
								duration: 500,
								easing: 'linear',
								queue: false
							}
						});
					} catch (err) {

					}
					return false;
				});


				winDow.on('resize', function () {
					var selector = $filter.find('li.active').attr('data-filter');

					$container.isotope({
						filter: selector,
						animationOptions: {
							duration: 500,
							easing: 'linear',
							queue: false
						}
					});
				});


				var filterItemA = $('.filter-btns li');

				filterItemA.on('click', function () {
					var $this = $(this);
					if (!$this.hasClass('active')) {
						filterItemA.removeClass('active');
						$this.addClass('active');
					}
				});
			}
		}

		enableMasonry();

		//MixItup Gallery
		if ($('.filter-list').length) {
			$('.filter-list').mixItUp();
		}

		//Message Box
		if ($('.message-box').length) {
			$('.message-box .close-btn').on('click', function (e) {
				$(this).parent('.message-box').fadeOut();
			});
		}

		//Accordion Box
		if ($('.accordion-box').length) {
			$(".accordion-box").on('click', '.acc-btn', function () {

				var outerBox = $(this).parents('.accordion-box');
				var target = $(this).parents('.accordion');

				if ($(this).hasClass('active') !== true) {
					$(outerBox).find('.accordion .acc-btn').removeClass('active ');
				}

				if ($(this).next('.acc-content').is(':visible')) {
					return false;
				} else {
					$(this).addClass('active');
					$(outerBox).children('.accordion').removeClass('active-block');
					$(outerBox).find('.accordion').children('.acc-content').slideUp(300);
					target.addClass('active-block');
					$(this).next('.acc-content').slideDown(300);
				}
			});
		}

		//Fact Counter + Text Count
		if ($('.count-box').length) {
			// $('.count-box').appear(function(){

			//     var $t = $(this),
			//         n = $t.find(".count-text").attr("data-stop"),
			//         r = parseInt($t.find(".count-text").attr("data-speed"), 10);

			//     if (!$t.hasClass("counted")) {
			//         $t.addClass("counted");
			//         $({
			//             countNum: $t.find(".count-text").text()
			//         }).animate({
			//             countNum: n
			//         }, {
			//             duration: r,
			//             easing: "linear",
			//             step: function() {
			//                 $t.find(".count-text").text(Math.floor(this.countNum));
			//             },
			//             complete: function() {
			//                 $t.find(".count-text").text(this.countNum);
			//             }
			//         });
			//     }

			// },{accY: 0});
		}

		//Progress Bar
		if ($('.progress-line').length) {
			// $('.progress-line').appear(function(){
			//     var el = $(this);
			//     var percent = el.data('width');
			//     $(el).css('width',percent+'%');
			// },{accY: 0});
		}

		//Tabs Box
		if ($('.tabs-box').length) {
			$('.tabs-box .tab-buttons .tab-btn').on('click', function (e) {
				e.preventDefault();
				var target = $($(this).attr('data-tab'));

				if ($(target).is(':visible')) {
					return false;
				} else {
					target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
					$(this).addClass('active-btn');
					target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
					target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab animated fadeIn');
					$(target).fadeIn(300);
					$(target).addClass('active-tab animated fadeIn');
				}
			});
		}

		//Price Range Slider
		if ($('.price-range-slider').length) {
			$(".price-range-slider").slider({
				range: true,
				min: 0,
				max: 90,
				values: [0, 84],
				slide: function (event, ui) {
					$("input.property-amount").val(ui.values[0] + " - " + ui.values[1]);
				}
			});

			$("input.property-amount").val($(".price-range-slider").slider("values", 0) + " - $" + $(".price-range-slider").slider("values", 1));
		}

		//LightBox / Fancybox
		if ($('.lightbox-image').length) {
			$('.lightbox-image').fancybox({
				openEffect: 'fade',
				closeEffect: 'fade',
				helpers: {
					media: {}
				}
			});
		}

		//Contact Form Validation
		if ($('#email-form').length) {
			// $('#submit').click(function(){

			//     var o = new Object();
			//     var form = '#email-form';

			//     var username = $('#email-form .username').val();
			//     var email = $('#email-form .email').val();

			//     if(username == '' || email == '')
			//     {
			//         $('#email-form .response').html('<div class="failed">Please fill the required fields.</div>');
			//         return false;
			//     }

			//     $.ajax({
			//         url:"sendemail.php",
			//         method:"POST",
			//         data: $(form).serialize(),
			//         beforeSend:function(){
			//             $('#email-form .response').html('<div class="text-info"><img src="images/icons/preloader.gif"> Loading...</div>');
			//         },
			//         success:function(data){
			//             $('form').trigger("reset");
			//             $('#email-form .response').fadeIn().html(data);
			//             setTimeout(function(){
			//                 $('#email-form .response').fadeOut("slow");
			//             }, 5000);
			//         },
			//         error:function(){
			//             $('#email-form .response').fadeIn().html(data);
			//         }
			//     });
			// });
		}

		//Subscribe Form
		if ($('#subscribe-form').length) {
			// $('#subscribe-newslatters').click(function(){

			//     var o = new Object();
			//     var form = '#subscribe-form';
			//     var email = $('#subscribe-form .email').val();

			//     if(email == '')
			//     {
			//         $('#subscribe-form .response').html('<div class="failed">Please enter your Email Address.</div>');
			//         return false;
			//     }

			//     $.ajax({
			//         url:"sendnewslatters.php",
			//         method:"POST",
			//         data: $(form).serialize(),
			//         beforeSend:function(){
			//             $('#subscribe-form .response').html('<div class="text-info"><img src="images/icons/preloader.gif"> Loading...</div>');
			//         },
			//         success:function(data){
			//             $('form').trigger("reset");
			//             $('#subscribe-form .response').fadeIn().html(data);
			//             setTimeout(function(){
			//                 $('#subscribe-form .response').fadeOut("slow");
			//             }, 5000);
			//         },
			//         error:function(){
			//             $('#subscribe-form .response').fadeIn().html(data);
			//         }
			//     });
			// });
		}

		// Scroll to a Specific Div
		if ($('.scroll-to-target').length) {
			$(".scroll-to-target").on('click', function () {
				var target = $(this).attr('data-target');
				// animate
				$('html, body').animate({
					scrollTop: $(target).offset().top
				}, 1500);

			});
		}

		// Elements Animation
		if ($('.wow').length) {
			// var wow = new WOW(
			//   {
			//     boxClass:     'wow',      // animated element css class (default is wow)
			//     animateClass: 'animated', // animation css class (default is animated)
			//     offset:       0,          // distance to the element when triggering the animation (default is 0)
			//     mobile:       false,       // trigger animations on mobile devices (default is true)
			//     live:         true       // act on asynchronously loaded content (default is true)
			//   }
			// );
			// wow.init();
		}


		var fast = 200;
		$(".dir-hover .portfolio-block").hover(function (e) {
			var liPos = $(this).offset();
			var bord = comingMouse(e.pageX - liPos.left, e.pageY - liPos.top, $(this).width(), $(this).height());
			var overlay = $(this).find($(".overlay"));
			switch (bord) {
				case "left":
					overlay.css({"top": 0, "left": "-100%"});
					overlay.stop().animate({"left": 0}, fast, "linear");
					break;
				case "right":
					overlay.css({"top": 0, "left": "100%"});
					overlay.stop().animate({"left": 0}, fast, "linear");
					break;
				case "top":
					overlay.css({"top": "-100%", "left": 0});
					overlay.stop().animate({"top": 0}, fast, "linear");
					break;
				case "bottom":
					overlay.css({"top": "100%", "left": 0});
					overlay.stop().animate({"top": 0}, fast, "linear");
					break;
			}
		}, function (e) {
			var liPos = $(this).offset();
			var bord = comingMouse(e.pageX - liPos.left, e.pageY - liPos.top, $(this).width(), $(this).height());
			var overlay = $(this).find($(".overlay"));
			switch (bord) {
				case "left":
					overlay.stop().animate({"left": "-100%"}, fast);
					break;
				case "right":
					overlay.stop().animate({"left": "100%"}, fast);
					break;
				case "top":
					overlay.stop().animate({"top": "-100%"}, fast);
					break;
				case "bottom":
					overlay.stop().animate({"top": "100%"}, fast);
					break;
			}
		});

		function comingMouse(hor, vert, larg, haut) {
			var top = Math.abs(vert),
				bottom = Math.abs(vert - haut),
				left = Math.abs(hor),
				right = Math.abs(hor - larg);

			var min = Math.min(top, bottom, left, right);
			switch (min) {
				case left:
					return "left";
				case right:
					return "right";
				case top:
					return "top";
				case bottom:
					return "bottom";
			}
		}


		/* ==========================================================================
		   When document is Resize, do
		   ========================================================================== */
		$(window).on('resize', function () {
			Mega_Menu();
		});

		/* ==========================================================================
		   When document is Scrollig, do
		   ========================================================================== */

		// $(window).on('scroll', function() {
		//     headerStyle();
		// });

		/* ==========================================================================
		   When document is loading, do
		   ========================================================================== */


		handlePreloader();
		defaultMasonry();
		enableMasonry();

	}
	getOTP = async (e) => {
		e.preventDefault();
		this.setState({error:""})

		if (this.state.mobileNo === "" || this.state.mobileNo === undefined) return this.setState({error:'Please enter mobile number'})
		try {
			const response = await setupRecaptcha(this.state.mobileNo,this.recaptchaSuccess,this.recaptchaError)
			this.setState({confirmObject:response})
			this.setState({otpState:"OTP_INPUT"})

		} catch (e) {
			this.setState({error:e.message})
		}
		console.log(this.state.mobileNo)

	}

	recaptchaSuccess=(response) => {
		console.log("Recaptcha Success")
	  }
	  recaptchaError =() => {
		console.log("Recaptcha Failed")
	  }

	verifyOTP = async (e) => {
		e.preventDefault();
		console.log("otp "+this.state.otp)
		if(this.state.otp==""|| this.state.otp==null) return;
		
		
		console.log(this.state.otp)

		this.setState({error:""})
		//const result = await this.state.confirmObject.confirm(this.state.otp)
		try{
			//const result=await this.state.confirmObject.confirm(this.state.otp)
					// User signed in successfully.
					var credential = PhoneAuthProvider.credential(this.state.confirmObject.verificationId, this.state.otp);
			console.log(credential)
			//credential.
			const res = await signInWithCredential(auth,credential)
			const idToken=await res.user.getIdToken()
			console.log(idToken)
			
			this.setState({idToken:idToken,otpState:"GOOGLE_LINK_MESSAGE"})
			// const user = result.user;
			// console.log(user)
			// console.log(result.verificationId)
			// ...
		}catch(e){
		// User couldn't sign in (bad verification code?)
		// ...
			this.setState({error:e.message})
		};
		//var credential = auth.PhoneAuthProvider.credential(this.state.confirmObject.verificationId, this.state.otp);
		//console.log(credential.toJSON())
	}

	agreeAndLink = async (e) => {
		e.preventDefault();
		this.setState({error:""})
		//const result = await this.state.confirmObject.confirm(this.state.otp)
		try{
			//credential.
			var responseUrl=this.getQueryVariable('response_url');
			console.log('responseUrl = '+responseUrl)
			const authCode = await this.getAuthCode(this.state.idToken)
			responseUrl+=util.format("&code=%s",encodeURIComponent(authCode))
			console.log('responseUrl+ = '+responseUrl)
			window.location.replace(responseUrl)
			// const user = result.user;
			// console.log(user)
			// console.log(result.verificationId)
			// ...
		}catch(e){
		// User couldn't sign in (bad verification code?)
		// ...
			this.setState({error:e.message})
		};
		//var credential = auth.PhoneAuthProvider.credential(this.state.confirmObject.verificationId, this.state.otp);
		//console.log(credential.toJSON())
	}

	cancel = async (e) => {
		e.preventDefault();
		this.setState({error:""})
		//const result = await this.state.confirmObject.confirm(this.state.otp)
		try{			
			//credential.
			var responseUrl=this.getQueryVariable('response_url');
			console.log('responseUrl = '+responseUrl)
			responseUrl+=util.format("&error=%s&error_description=%s",'cancelled',encodeURIComponent('User cancelled operation'))
            console.log('responseUrl+ = '+responseUrl)
            window.location.replace(responseUrl)
			// const user = result.user;
			// console.log(user)
			// console.log(result.verificationId)
			// ...
		}catch(e){
		// User couldn't sign in (bad verification code?)
		// ...
			this.setState({error:e.message})
		};
		//var credential = auth.PhoneAuthProvider.credential(this.state.confirmObject.verificationId, this.state.otp);
		//console.log(credential.toJSON())
	}

	getAuthCode = async (idToken)=>{
		//Get Auth code from https://asia-south1-yax-iot.cloudfunctions.net/getHttpsAuthCode

		// curl -H 'Content-Type: application/json' \
		// -d '{		"token": "idToken"		}' \
		// -X POST \
		// https://asia-south1-yax-iot.cloudfunctions.net/getHttpsAuthCode
		const recipeUrl = 'https://asia-south1-yax-iot.cloudfunctions.net/getHttpsAuthCode';
		const postBody = {
			token: idToken
		};
		const reqBody=JSON.stringify(postBody);
		console.log(reqBody)
		const requestMetadata = {
			mode: 'cors',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: reqBody
		};
		
		const res = await fetch(recipeUrl, requestMetadata);
		const authCodeJson = await res.json()
		console.log("authCodeJson - "+authCodeJson.authCode)				
		return authCodeJson.authCode;
	}


	render=()=> {
		
		return (
			<Fragment>
				<div className="page-wrapper">
					{/* Preloader */}
					<div className="preloader">
						<div className="cws_loader">
							<span>LOADING...</span>
							<div className="hex" />
							<div className="hex" />
							<div className="hex" />
							<div className="hex" />
							<div className="hex" />
							<div className="hex" />
							<div className="hex" />
						</div>
					</div>
					{/* Main Header*/}

					{/*End Main Header */}
					{/*Page Title*/}
					<section className="page-title">
						{/* Background Layers */}
						<div className="background-layers">
							<div
								className="cws-image-bg"
								style={{
									backgroundImage: "url(https://via.placeholder.com/1920x1080)"
								}}
							>
								<div className="cws-overlay-bg" />
							</div>
						</div>
						<div className="auto-container">
							<h1>Link YAX Home to Google</h1>
						</div>
					</section>
					{/*End Page Title*/}
					{/*Login Section*/}
					<section className="login-section">
						<div className="auto-container">
							{/* Login Form */}
							<div className="login-form">
								<h2>Login</h2>
								{/*Login Form*/}
								<div style={{display: this.state.otpState === "MOBILE_INPUT" ? 'block' : 'none'}}>
								<Form onSubmit={(e) => this.getOTP(e)} >
									<div className="form-group">
										<label>Mobile number *</label>
										<PhoneInput
											placeholder="Enter mobile number"
											id="phoneInput"
											onChange={(x)=>this.setState({mobileNo:x})}  defaultCountry={this.state.countryCode} />
										{this.state.error && <div>{this.state.error}</div>}
									</div>

									<div className="form-group">
										<input
											className="theme-btn"
											type="submit"
											value="Get OTP"
											name="submit-form"
										/>
									</div>

									{/* <div className="form-group">
              <input
                type="checkbox"
                name="shipping-option"
                id="account-option-1"
              />
              &nbsp; <label htmlFor="account-option-1">Remember me</label>
            </div>
            <div className="form-group pass">
              <a href="#" className="psw">
                Lost your password?
              </a>
            </div> */}
									<div id="recaptcha-container"></div>
								</Form>
								</div>
								<div style={{display: this.state.otpState === "GOOGLE_LINK_MESSAGE" ? 'block' : 'none'}} >
								
									
									<ul>
										<li>By signing in, you are authorizing Google to control your devices</li>
									</ul>
									
									<div className="form-group">
										<input
											className="theme-btn"
											type="submit"
											name="submit-form"
											value="Agree and Link"
											onClick={this.agreeAndLink}									
										/>&nbsp;<input
										className="theme-btn"
										type="submit"
										value="Cancel"
										name="submit-form" 
										onClick={this.cancel}									
									/>
									</div>
								
								</div>
<div style={{display: this.state.otpState === "OTP_INPUT" ? 'block' : 'none'}} >
								<Form onSubmit={this.verifyOTP} >
									<label>OTP is sent to your mobile number at {this.state.mobileNo}</label>
									<Form.Group className='mb-3' controlId='formOtp'>
										<Form.Control type="otp" placeholder='OTP' onChange={
											(e) => this.setState({otp:e.target.value})
											}>
											</Form.Control>
									</Form.Group>

									<div id="recaptcha-container"></div>
									<div className="form-group">
										<input
											className="theme-btn"
											type="submit"
											value="Verify"
											name="submit-form"									
										/>
									</div>
								</Form>
								</div>
							</div>
							{/*End Login Form */}
						</div>
					</section>
					{/*End Login Section*/}
					<Footer></Footer>
				</div>
				{/* End Page Wrapper */}
				{/* Scroll To Top */}
				<div className="scroll-to-top scroll-to-target" data-target="html">
					<span className="fa fa-long-arrow-alt-up" />
				</div>
			</Fragment>
		);
	}
}
export default LinkGoogle